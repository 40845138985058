import {environment} from '../environments/environment';
import {NgForm} from '@angular/forms';

const baseUrl = environment.baseUrl;
const user = 'merchant';

export const Globals = {

    appName: 'DarkCarz',
    defaultImage: 'https://ondemandstartup.s3.us-west-2.amazonaws.com/profileImages/1000X1000/1540906183439-icon_default_image.png',
    defaultCurrency: '$',
    stripe: environment.stripe,
    regex: {
        email: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
        ssn: /\d{3}-\d{2}-\d{4}/,
        date: /\d{2}\/\d{2}\/\d{4}/,

        // password must be 8 digits and contain 1 uppercase and lowercase letters, 1 special character, 1 numeric
        passwordStrength: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        passRegex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/
    },

    // to be used with the TextMask Module from angular2-text-mask package
    masks: {
        phone: [
            '(',
            /[1-9]/,
            /\d/,
            /\d/,
            ')',
            ' ',
            /\d/,
            /\d/,
            /\d/,
            '-',
            /\d/,
            /\d/,
            /\d/,
            /\d/
        ],
        date: [
            /\d/,
            /\d/,
            '/',
            /\d/,
            /\d/,
            '/',
            /\d/,
            /\d/,
            /\d/,
            /\d/
        ],
        ssn: [
            /[0-9]/,
            /\d/,
            /\d/,
            '-',
            /\d/,
            /\d/,
            '-',
            /\d/,
            /\d/,
            /\d/,
            /\d/
        ],
        zip: [
            /[0-9]/,
            /\d/,
            /\d/,
            /\d/,
            /\d/
        ]
    },

    urls: {
        login: baseUrl + user + '/login',
        send2Fa: baseUrl + user + '/send/verification-code',
        logout: baseUrl + user + '/logout',
        uploadImage: baseUrl + 'upload/vehicle-image',
        uploadPDF: baseUrl + 'upload/commercial-insurance-document',
        getPrfixes: baseUrl + '/country/codes',
        getAllLanguages: baseUrl + '/active-languages',
        getLanguageLevels: baseUrl + '/language-level',
        getCSVDetails: baseUrl + '/user-detail-to-csv',
        getCSVJob: baseUrl + '/job-to-csv',
        gdprLoging: baseUrl + user + '/view-info',
        getJobChatHistory: baseUrl + '/job-chathistory-to-csv',
        deleteUserCompletely: baseUrl + user + '/mark/delete',
        forgotPassword: baseUrl + user + '/forgot/password',
        changePassword: baseUrl + 'change/password',
        resetPassword: baseUrl + 'reset/password',
        DarkCarz: {
            activeBlockUser: baseUrl + user + '/block-user-account',
            workers: {
                fetchSPList: baseUrl + user + '/fetch-sp-list?status=:status&jobTypeId=:jobTypeId&search_text=:search_text&limit=:limit&offset=:offset',
                fetchJobTypes: baseUrl + user + '/fetch-services-list',
                fetchProfileData: baseUrl + user + '/fetch-sp-profile-data?workerId=:workerId',
                fetchSpDetails: baseUrl + user + '/fetch-sp-details?workerId=:workerId',
                fetchNewSpUser: baseUrl + user + '/fetch-new-sp-list?status=:status&jobTypeId=:jobTypeId&search_text=:search_text&limit=:limit&offset=:offset',
                fetchSpJobList: baseUrl + user + '/fetch-sp-jobs?workerId=:workerId&search_text=:search_text&status=:status&limit=:limit&offset=:offset',
                fetchSpJobDetails: baseUrl + user + '/fetch-sp-job-detail/:jobId',
                approveWorker: baseUrl + user + '/approve-worker-account',
                rejectWorker: baseUrl + user + '/reject-worker-account',
                getNewCount: baseUrl + user + '/get/new-worker-count',
                editWorker: {
                    getProfile: baseUrl + user + '/get-worker-profile?workerId=:workerId',
                    updateWorkerProfile: baseUrl + user + '/update-worker-profile?workerId=:workerId',
                    getWorkerServices: baseUrl + user + '/get-worker-services?workerId=:workerId',
                    updateWorkerServices: baseUrl + user + '/update-worker-service',
                    getIdDoc: baseUrl + user + '/get-worker-identitydoc?workerId=:workerId',
                    updateIdDoc: baseUrl + user + '/update-worker-identitydoc',
                    getProfDoc: baseUrl + user + '/get-worker-professional-doc?workerId=:workerId',
                    updateProfDoc: baseUrl + user + '/update-worker-professional-doc',
                    getBankDetails: baseUrl + user + '/get-worker-bank-info/:workerId',
                    updateBankDetails: baseUrl + user + '/update-worker-bank-info/:workerId',
                    getUserLanguageDetails: baseUrl + user + '/get-worker-languages?workerId=:workerId',
                    updateUserLanguages: baseUrl + user + '/update-worker-language'
                }
            },
            drivers: {
                fetchDriversInfo: baseUrl + user + '/drivers?driversType=:driversType&limit=:limit&offset=:offset&searchFilter=:searchFilter',
                fetchDriversOnMap: baseUrl + user + '/map/drivers?driversType=:driversType&latitude=:latitude&longitude=:longitude&offset=:offset&limit=:limit',
                fetchDriverDetails: baseUrl + user + '/driver/:driverid',
                fetchDriverhistory: baseUrl + user + '/driver/:driverid/jobs?listType=:listType&limit=:limit&offset=:offset',
                addDriver: baseUrl + user + '/create-driver',
                updateDriverPersonalInfo: baseUrl + user + '/driver/:id/profile',
                updateDriverVehicle: baseUrl + user + '/driver/:id/vehicle',
                updateDriverBankinfo: baseUrl + user + '/driver/:id/bank-info',
                blockDriver: baseUrl + user + '/driver/:id/block?isBlocked=:block'
            },
            earnings: {
                // tslint:disable-next-line:max-line-length
                fetchDriverEarnings: baseUrl + user + '/driver/:id/monthly/jobs?month=:month&year=:year&limit=:limit&offset=:offset&searchFilter=:searchfilter',
                fetchEarnings: baseUrl + user + '/earnings?month=:month&year=:year&limit=:limit&offset=:offset&searchFilter=:searchFilter'
            },
            vehicles: {
                getVehcilesDetailToADD: baseUrl + user + '/vehicles/info',
                fetchVehicles: baseUrl + user + '/selected/vehicles/list?limit=:limit&offset=:offset',
                newVehicle: baseUrl + user + '/create-vehicle',
                fetchVehicleEditDetails: baseUrl + user + '/vehicle/:id',
                updateVehicle: baseUrl + user + '/vehicle/:id'
            },
            merchantProfile: {
                fetchProfileInfo: baseUrl + user + '/profile',
                updateProfile: baseUrl + user + '/profile'
            },
            bank: {
                fetchBankInfo: baseUrl + user + '/bank-info',
                updateBankInfo: baseUrl + 'bank/account',
                updateBankInfoo: baseUrl + 'add/bank/account'
            },
            users: {
                fetchUsersList: baseUrl + '/admin/fetch-users-list?status=:status&jobTypeId=:jobTypeId&search_text=:search_text&limit=:limit&offset=:offset',
                fetchUsersJobLists: baseUrl + '/admin/fetch-user-jobs-list?userId=:userId&search_text=:search_text&limit=:limit&offset=:offset&status=:status',
                fetchUserJobDetails: baseUrl + '/admin/fetch-user-job-detail?jobId=:jobId'
            },
            jobs: {
                fetchDriverJob: baseUrl + user + '/job/:jobid',
                fetchJobsList: baseUrl + '/admin/fetch-jobs-list?status=:status&search_text=:search_text&limit=:limit&offset=:offset',
                getRides: baseUrl + user + '/jobs?listType=:listtype&limit=:limit&offset=:offset&searchFilter=:searchfilter',
                getJobDetails: baseUrl + user + '/job/:id'
            },
            notification: {
                getNotifications: baseUrl + '/admin/general/notifications/:userType',
                postNotifications: baseUrl + '/admin/update/general-notifications'
            },
            RolesSecurity: {
                getRoles: baseUrl + '/admin/subAdmins/listing',
                AddRoles: baseUrl + '/admin/subAdmin/create',
                getSingleRole: baseUrl + '/admin/subAdmin/:subAdminId',
                updateRoles: baseUrl + '/admin/subAdmin/:subAdminId'
            },
            Referrals: {
                getReferrals: baseUrl + '/referral-listing?userType=:userType&limit=:limit&offset=:offset&search_text=:search_text',
                getSettingsData: baseUrl + '/admin/fetch/referral',
                setSettingsData: baseUrl + '/admin/update/referral'
            },
            Loging: {
                getLogs: baseUrl + '/activity/get/logs'
            }
        },

        signUp: {
            companyRegistration: baseUrl + user + '/company-registration',
            companyInsurance: baseUrl + user + '/insurance-details',
            companyBank: baseUrl + 'bank/account',
            bankInfo: baseUrl + 'add/bank/account'
        },

        verificationDocument: {
            uploadVerificationDocument: baseUrl + user + '/save/document'
        },

        accountStatus: {
            checkStatus: baseUrl + user + '/check/status'
        },
        authentication: {
            getCurrentUser: baseUrl + user + '/profile',
            logout: baseUrl + user + '/logout'
        },

        dashBoard: {
            analytics: baseUrl + 'dashboard?month=:month&year=:year'
        },

        notification: {
            getNotifications: baseUrl + 'notifications?limit=:limit&offset=:offset'
        },

        post: {
            headerNotifcations: baseUrl + '/admin/fetch/notification'
        },

        map: {
            activeJobs: baseUrl + '/admin/fetch-active-sp-list',
            inactiveJobs: baseUrl + '/admin/fetch-inactive-sp-list',
            count: baseUrl + '/admin/fetch-sp-count',
            cancelJob: baseUrl + '/admin/cancel-job',
            finishJob: baseUrl + '/admin/'
        },

        job: {
            detail: baseUrl + '/admin/fetch-sp-job-detail'
        },

        settings: {

            profile: {
                update: baseUrl + '/admin/updateProfile',
                get: baseUrl + '/admin/currentAccount'
            },

            commission: {
                get: baseUrl + '/admin/get/commission',
                update: baseUrl + '/admin/update/commission'
            },

            services: {
                list: baseUrl + '/admin/fetch-services-list',
                get: baseUrl + '/admin/get-service',
                add: baseUrl + '/admin/add-service',
                update: baseUrl + '/admin/update-service',
                delete: baseUrl + '/admin/delete-service'
            },

            promocodes: {
                list: baseUrl + '/admin/get/promocodes',
                add: baseUrl + '/admin/add/promocode',
                update: baseUrl + '/admin/promocode/promoCodeStatusUpdate',
                fetch: baseUrl + '/admin/get/promocode',
                delete: baseUrl + '/admin/delete/promocode'
            },

            reasons: {
                add: baseUrl + '/admin/add/reasons',
                get: baseUrl + '/admin/get/reasons',
                update: baseUrl + '/admin/update/reason'
            },

            payouts: {
                list: baseUrl + '/admin/get-worker-earnings',
                get: baseUrl + '/admin/get-worker-earning-detail',
                pay: baseUrl + '/admin/payout'
            },

            serviceCharges: {
                get: baseUrl + '/admin/get/setting',
                edit: baseUrl + '/admin/update/setting'
            },

            termsCondition: {
                list: baseUrl + '/admin/get/termsconditions',
                add: baseUrl + '/admin/add/termscondition',
                fetch: baseUrl + '/admin/get/termscondition'
            },
            privacyPolicy: {
                list: baseUrl + '/admin/get/privacypolicies',
                add: baseUrl + '/admin/add/privacypolicy',
                fetch: baseUrl + '/admin/get/privacypolicy'
            }
        }


    }
};

export const checkIfOnlySpaces = (form : NgForm, control : string) => {
    // value exists but only spaces
    if (form.controls[control].value && form.controls[control].value.trim().length === 0) {

        form.controls[control].setValue('');
        return form.controls[control].setErrors({required: true});

    } else if (form.controls[control].value) {
        // trim the value
        form.controls[control].setValue(form.controls[control].value.trim());
    } else {
        form.controls[control].setValue('');
    }

}
