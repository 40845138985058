import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { mustMatch } from 'src/app/common/helpers/must-match';
import {Router} from '@angular/router';
import {Globals, checkIfOnlySpaces} from 'src/app/Globals';
import {HttpService} from '../../../services/http-service';
import {el} from '@angular/platform-browser/testing/src/browser_util';

@Component({
  selector: 'app-tcp-and-insurance',
  templateUrl: './tcp-and-insurance.component.html',
  styleUrls: ['./tcp-and-insurance.component.scss']
})
export class TCPAndInsuranceComponent implements OnInit {
  uploadingImage: boolean;
  fileToUpload: any;
  globals = Globals;
  model: any = {};
  isLoadingResults = false;
  rForm: FormGroup;
  pdf = false;
  constructor(private fb: FormBuilder, private service: HttpService, private router: Router) { }
  get rFormControls() { return this.rForm.controls; }

  ngOnInit() {
    this.rForm = this.fb.group({
      tcpNumber: ['', Validators.required],
      commercialInsuranceNumber: ['', Validators.required],
      commercialInsuranceDocument: ['', Validators.required],
      sameCommercialInsurance: ['', Validators.required],
      check: [false]
    });
  }

  // [\+]\d{2}
  // [\+]\d{2}[\(]\d{2}[\)]\d{4}[\-]\d{4}

  // login() {

  //   this.isLoadingResults = true;

  //   const params = {
  //     companyName: this.rForm.get('companyName'),
  //     email: this.rForm.get('email'),
  //     phoneNumber: this.rForm.get('phoneNumber'),
  //     phoneNumberPrefix: '+92',
  //     password: this.rForm.get('password'),
  //   };

  //   console.log(params);


  //   this.service.putRequest('http://52.42.97.250:3000/api/v1/merchant/login', params)
  //     .pipe(
  //       map(res => res),
  //     )
  //     .subscribe(
  //       res => {
  //         this.isLoadingResults = false;
  //         if (res.success === 1) {
  //           localStorage.setItem('DarkCarz-merchant-data', JSON.stringify(res.data.account));
  //           /*this.show2FA=true;
  //           this.service.showSuccess(res.data.code, 'Code');*/
  //           this.router.navigate(['/merchant/dashboard']);
  //         } else if (res.success === 0) {
  //           this.service.showError(res['message'], 'Sign In');
  //         }
  //       },
  //       err => {
  //         this.isLoadingResults = false;
  //         this.service.showError(err.error.message);
  //       }
  //     );
  // }

  uploadImage(files: FileList, type) {

    if (files[0].type === 'image/gif') {
      this.service.showError('Gif images are not allowed', 'Sign Up');
      return;
    }
    if (files[0].size <= 5000000) {
// console.log(files);
      const file = files[0].name.substring(files[0].name.lastIndexOf('.'));
      // console.log('fi;le', file);
      let url;
      if (file === '.pdf') {
        this.pdf = true;
        url = this.globals.urls.uploadImage;
      } else {
        url = this.globals.urls.uploadImage;
      }
      this.uploadingImage = true;
      this.fileToUpload = files.item(0);

      this.isLoadingResults = true;

      this.service.uploadImage(url, this.fileToUpload).subscribe(
        res => {
          if (type === 1) {
            this.model.serviceImage = res.data.url;
            // this.rForm.controls['commercialInsuranceDocument'].patchValue(res.data.url);
          } else {
            this.model.dashboardImage = res.data.url;
          }

          this.uploadingImage = false;
          this.isLoadingResults = false;

        },
        err => {

          this.service.showError(err.error.message);
          this.isLoadingResults = false;

        },
      );
    }
    else this.service.showError('Image size should be less then 5MB');
  }
  goToNextStep() {
    const params = {
      tcpNumber: this.rForm.controls['tcpNumber'].value,
      commercialInsuranceNumber: this.rForm.controls['commercialInsuranceNumber'].value,
      commercialInsuranceDocumentUrl: this.model.serviceImage,
      isSameCommercialInsuranceDocumentForAllCars: this.rForm.controls['check'].value,
    };
    const url = this.globals.urls.signUp.companyInsurance;
    this.service.putRequest(url, params).subscribe(
      res => {
        if (res.success === 1) {
          this.service.showSuccess(res.message, 'SignUp');
          this.router.navigate(['login']);
        } else {
          this.service.showError(res.message, 'SignUp ERROR');
        }
      },
      err => {
        this.service.showError(err.message, 'ERROR');
      }
    );
  }

}
