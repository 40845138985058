import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { ToastrModule } from 'ngx-toastr';
import { LoginComponent } from './pages/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpRequestInterceptor } from './services/http-intercepter.service';
import { LogoutComponent } from './pages/logout/logout.component';
// import { GdprDirective } from './directives/gdpr.directive';
import { HideDigitsPipe } from './pipes/hide-digits.pipe';
import { RegistrationModule } from './pages/registration/registration.module';
import {MatProgressSpinnerModule} from '@angular/material';
// import { BsDropdownModule } from 'ngx-bootstrap/dropdown';


// import { HomeComponent } from './pages/home/home.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LogoutComponent,
    // GdprDirective,
    HideDigitsPipe,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgbModule,
    AppRoutingModule,
    ToastrModule.forRoot(),
    RegistrationModule,
    MatProgressSpinnerModule,
    // BsDropdownModule.forRoot(),

  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
