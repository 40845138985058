// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  
  baseUrl: 'https://stagingapi.darkcarz.com/api/v1/',
  // baseUrl: 'https://api.darkcarz.com/api/v1/',
  // baseUrl: 'http://localhost:4000/api/v1/',
 
  mapApiKey: 'AIzaSyDohS3jaSzofyKGGKUO0uNhOOBTiCY1fXQ',

  // STRIPEkEY
  // stripe: 'pk_test_1K3VCO0Ct1h7ci55qUKDUJ2Z004hV8yYIE',
  // socket: 'https://api.darkcarz.com',
  stripe: 'pk_test_51PpXg0RoshmQPEA2gQZrslaGw5afc9y7Phk2acnNuMPZWFAsHjHyUzcyMst1arvr3HlgKsKmdSkbpRMu8SsQv83t00d4HZM8OV',
  // socket: 'https://api.darkcarz.com/api/v1/'
  socket:'https://stagingapi.darkcarz.com/',
  // socket: 'http://localhost:4000',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
