import {Component, OnInit} from '@angular/core';
import {HttpService} from '../../services/http-service';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {Globals} from '../../Globals';

@Component({selector: 'app-login', templateUrl: './login.component.html', styleUrls: ['./login.component.scss']})
export class LoginComponent implements OnInit {

    globals = Globals;

    httpSub$ : Subscription = null;

    model : any = {};

  isLoadingResults: boolean = false;
  show2FA: boolean = false;
  year;

    constructor(private service : HttpService, private router : Router, private toastrService : ToastrService) {}

  ngOnInit() {
    let today = new Date();
    this.year = today.getFullYear();
    localStorage.removeItem('DarkCarz-merchant-data');
  }

    login() {
        this.isLoadingResults = true;
        const params = {
            email: this.model.email,
            password: this.model.password
        };
        this.httpSub$ = this.service.putRequest(this.globals.urls.login, params).pipe(map(res => res),).subscribe(res => {
            this.isLoadingResults = false;
            if (res.success === 1) { // console.log(res.data);
                if (res.data.isSignupCompleted === true) {
                    localStorage.setItem('DarkCarz-merchant-data', JSON.stringify(res.data));
                    this.router.navigate(['/merchant/dashboard']);
                } else {
                    if (res.data.signupStepCompleted === 0) {
                        this.router.navigate(['/registration/verification']);
                    } else if (res.data.signupStepCompleted === 1) {
                        this.router.navigate(['/registration/bank-info']);
                    } else if (res.data.signupStepCompleted === 2) {
                        this.router.navigate(['/registration/insurance-details']);
                    }
                }
            } else if (res.success === 0) {
                this.service.showError(res['message'], 'Sign In');
            }
        }, err => {
            this.isLoadingResults = false;
            this.service.showError(err.error.message);
        });
    }


    // login2Fa() {
    // const params = {
    //     email: this.model.email,
    //     password: this.model.password,
    //     code: this.model.code
    // };
    // this.isLoadingResults = true;
    // this.httpSub$ = this.service.postRequest(this.globals.urls.login, params)
    //     .pipe(
    //       map(res => res),
    //     )
    //     .subscribe(
    //       res => {
    //         this.isLoadingResults = false;
    //         if (res.success == 1) {
    //           localStorage.setItem('DarkCarz-merchant-data', JSON.stringify(res.data.account));
    //           // this.show2FA=true;
    //           this.service.showSuccess('User signed in successfully', 'Sign In');
    //           this.router.navigate(['/dashboard']);
    //         }
    //         else if (res.success == 0) {
    //           this.service.showError(res['message'], 'Sign In');
    //         }
    //       },
    //       err => {
    //         this.isLoadingResults = false;
    //         this.service.showError(err.error.message);
    //       }
    //     );
    // }

}
