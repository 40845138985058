import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RegistrationComponent } from './registration.component';
import { RegistrationDetailsComponent } from './registration-details/registration-details.component';
import { BankInfoComponent } from './bank-info/bank-info.component';
import { TCPAndInsuranceComponent } from './tcp-and-insurance/tcp-and-insurance.component';
import { VerificationComponent } from './verification/verification.component';

const routes: Routes = [
  {
    path: '',
    component: RegistrationComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'registration-details',
      },
      {
        path: 'registration-details',
        component: RegistrationDetailsComponent
      },
      {
        path: 'bank-info',
        component: BankInfoComponent
      },
      {
        path: 'insurance-details',
        component: TCPAndInsuranceComponent
      },
      {
        path: 'verification',
        component: VerificationComponent
      },
    ],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RegistrationRoutingModule { }
