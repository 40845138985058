import {Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {Globals} from 'src/app/Globals';
import * as moment from 'moment';
import {HttpService} from 'src/app/services/http-service';
// import { Router } from '@angular/router';
// import { FormBuilder, FormGroup } from '@angular/forms';
// import { Subscription } from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {ToastrService} from 'ngx-toastr';

@Component({selector: 'app-verification', templateUrl: './verification.component.html', styleUrls: ['./verification.component.scss']})
export class VerificationComponent implements OnInit {

    public previewImage = false;
    public previewImageUrl = '';
    globals = Globals;
    expiryDate : any;
    isLoadingResults = false;
    httpSub$ : Subscription = null;
    // variable type null
    model : any = {};
    fileToUpload : File = null;
    comingFrom : any;
    editMode = false;
    driverDetails : any;
    submitted = true;
    constructor(private service : HttpService, private route : ActivatedRoute, private router : Router, private toastr : ToastrService) {}

    ngOnInit() {

        // this.service.getRequest(this.globals.urls.authentication.getCurrentUser).subscribe(res => {

        //     if (res.success === 1) {
        //         this.model.identityFrontImage = res.data.identityFrontImage;
        //         this.model.identityBackImage = res.data.identityBackImage;

        //         if (this.model.identityFrontImage && this.model.identityBackImage && !res.data.isAccountApproved) {
        //             this.submitted = false;
        //         }
        //     } else {
        //         console.log('ERROR', res.message);
        //         this.service.showError(res.message, 'ERROR');
        //     }
        //     this.isLoadingResults = false;
        // }, err => {
        //     this.isLoadingResults = false;
        //     // this.service.showError(err.error.message)
        // },);

      //   setTimeout(() => {
      //     this.router.navigate(['/login']);
      // }, 3000);
    }

    uploadPackageImage(files : FileList, type) {
        console.log(type);
        if (files[0].type === 'image/gif') {
            this.service.showError('Gif images are not allowed', 'Profile details');
            return;
        }
        this.fileToUpload = files.item(0);
        this.isLoadingResults = true;
        this.httpSub$ = this.service.uploadImage(this.globals.urls.uploadImage, this.fileToUpload).subscribe(res => {
            console.log(res);
            this.isLoadingResults = false;
            if (type === 1) {
                this.model.identityFrontImage = res.data.url;
            } else if (type === 2) {
                this.model.identityBackImage = res.data.url;
            }
            console.log(this.model);
        }, err => {
            this.isLoadingResults = false;
            // this.service.showError(err.error.message)
        },);
    }
    goToNextStep() {
        const url = this.globals.urls.verificationDocument.uploadVerificationDocument
        const params = {
            identityFrontImage: this.model.identityFrontImage,
            identityBackImage: this.model.identityBackImage
        };
        console.log(params);
        this.service.postRequest(url, params).subscribe(res => {
            if (res.success === 1) {
                console.log('SUCCESS', res.message);
                this.service.showSuccess(res.message, 'Success');
                this.router.navigate(['registration/bank-info']);
                // const urll = this.globals.urls.accountStatus.checkStatus;
                // this.service.getRequest(urll).subscribe(res => {
                //     if (res.success == 1) {
                //         if (res.data.isAccountApproved) {
                //             this.router.navigate(['registration/bank-info']);

                //         } else {
                //             this.submitted = false;
                //             this.service.showError(res.message, 'ERROR');
                //         }

                //     }
                // }, err => {
                //     console.log('err', err.message);
                // })

            } else {
                console.log('ERROR', res.message);
                this.service.showError(res.message, 'ERROR');
            }
        }, err => {
            console.log('err', err.message);
        });

    }


}
