import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegistrationRoutingModule } from './registration-routing.module';
import { RegistrationComponent } from './registration.component';
import { RegistrationDetailsComponent } from './registration-details/registration-details.component';
import {MatTabsModule, MatCardModule, MatCheckboxModule, MatProgressSpinnerModule, MatFormFieldModule, MatInputModule, MatNativeDateModule} from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxStripeModule } from 'ngx-stripe';
import { BankInfoComponent } from './bank-info/bank-info.component';
import { TCPAndInsuranceComponent } from './tcp-and-insurance/tcp-and-insurance.component';
import { VerificationComponent } from './verification/verification.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { MatSelectModule, MatIconModule } from '@angular/material';
 
@NgModule({
  imports: [
    CommonModule,
    RegistrationRoutingModule,
    MatNativeDateModule,
    FormsModule,
    ReactiveFormsModule,
    NgxStripeModule.forRoot(),
    MatTabsModule,
    MatCardModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    GooglePlaceModule,
    MatSelectModule,
    MatIconModule
  ],
  declarations: [RegistrationComponent, RegistrationDetailsComponent, BankInfoComponent, TCPAndInsuranceComponent, VerificationComponent]
})
export class RegistrationModule { }
