import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {

  loader: boolean = false;

  constructor() { }

  ngOnInit() {
  }
  changeLoader () {
    this.loader = !this.loader;
    console.log('called');
}
}
