import {Component, OnInit} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {mustMatch} from 'src/app/common/helpers/must-match';
import {StripeService} from 'ngx-stripe';
import {Router} from '@angular/router';
import {Globals} from '../../../Globals';
import {HttpService} from '../../../services/http-service';

@Component({selector: 'app-bank-info', templateUrl: './bank-info.component.html', styleUrls: ['./bank-info.component.scss']})
export class BankInfoComponent implements OnInit {
    globals = Globals;
    isLoadingResults = false;
    rForm : FormGroup;

    constructor(private fb : FormBuilder, private router : Router, private stripeService : StripeService, private service : HttpService) {}

    get rFormControls() {
        return this.rForm.controls;
    }

    ngOnInit() {
        this.stripeService.setKey(this.globals.stripe);
        this.rForm = this.fb.group({
            routingNumber: [
                '',
                [
                    Validators.required, Validators.minLength(9)
                ]
            ],
            bankAccountNumber: ['', Validators.required],
            ssnlast4: ['', Validators.required]
        });
    }

    // [\+]\d{2}
    // [\+]\d{2}[\(]\d{2}[\)]\d{4}[\-]\d{4}

    // login() {

    // this.isLoadingResults = true;

    // const params = {
    //     companyName: this.rForm.get('companyName'),
    //     email: this.rForm.get('email'),
    //     phoneNumber: this.rForm.get('phoneNumber'),
    //     phoneNumberPrefix: '+92',
    //     password: this.rForm.get('password'),
    // };

    // console.log(params);


    // this.service.putRequest('http://52.42.97.250:3000/api/v1/merchant/login', params)
    //     .pipe(
    //       map(res => res),
    //     )
    //     .subscribe(
    //       res => {
    //         this.isLoadingResults = false;
    //         if (res.success === 1) {
    //           localStorage.setItem('DarkCarz-merchant-data', JSON.stringify(res.data.account));
    //           /*this.show2FA=true;
    //           this.service.showSuccess(res.data.code, 'Code');*/
    //           this.router.navigate(['/merchant/dashboard']);
    //         } else if (res.success === 0) {
    //           this.service.showError(res['message'], 'Sign In');
    //         }
    //       },
    //       err => {
    //         this.isLoadingResults = false;
    //         this.service.showError(err.error.message);
    //       }
    //     );
    // }


    // goToNextStep() {
    // const url = this.globals.urls.signUp.companyBank;
    // this.stripeService.createToken('bank_account', {
    //     country: 'US',
    //     currency: 'usd',
    //     routing_number: this.rForm.controls['routingNumber'].value,
    //     account_number: this.rForm.controls['bankAccountNumber'].value
    // }).subscribe(
    //     res => {
    //       if (res.token) {
    //         const params = {
    //           bankToken: res.token.id
    //         };
    //         this.service.showSuccess('Processing.....', 'Please Wait');
    //         // console.log(params);
    //         // url = url.replace(':workerId', this.spId);
    //         this.service.postRequest(url, params)
    //           .subscribe(
    //             response => {
    //               if (response.success === 1) {
    //                 this.service.showSuccess('Bank Account Added Successfully', 'Bank');
    //                 this.router.navigate(['/registration/insurance-details']);
    //               } else {
    //                 this.service.showError(response.message, 'ERROR');
    //               }
    //             },
    //             err => {
    //               this.isLoadingResults = false;
    //               this.service.showError(err, 'ERROR');
    //             }
    //           );
    //         // this.httpService.showSuccess('Stripe', 'Stripe');
    //       } else {
    //         this.service.showError(res.error.message, 'Error');
    //       }
    //     },
    //     err => {
    //       this.isLoadingResults = false;
    //       this.service.showError(err, 'Error');
    //     }
    // );
    // }


    goToNextStep() {

        const url = this.globals.urls.signUp.bankInfo;
        const params = {
            routingNumber: this.rForm.controls['routingNumber'].value,
            accountNumber: this.rForm.controls['bankAccountNumber'].value,
            ssnlast4: this.rForm.controls['ssnlast4'].value
        }
        this.service.postRequest(url, params).subscribe(res => {

            if (res.success === 1) {
                this.service.showSuccess('Bank Account Added Successfully', 'Bank');
                this.router.navigate(['/registration/insurance-details']);
            } else {
                this.service.showError(res.message, 'ERROR');
            }
        }, err => {
            this.isLoadingResults = false;
            this.service.showError(err, 'ERROR');
        })
    }
}
