import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import {FormBuilder, FormGroup, RequiredValidator, Validators} from '@angular/forms';
import {HttpService} from '../../../services/http-service';
import {map} from 'rxjs/operators';
// import {Spinner} from
import {Globals} from '../../../Globals';
import {Router} from '@angular/router';
import {mustMatch} from '../../../common/helpers/must-match';
import * as moment from 'moment';

@Component({selector: 'app-registration-details', templateUrl: './registration-details.component.html', styleUrls: ['./registration-details.component.scss']})
export class RegistrationDetailsComponent implements OnInit {

    globals = Globals;
    // isLoadingResults = true;
    rForm : FormGroup;
    userAddress : string = '';
    city : string = '';
    country : string = '';
    state : string = '';
    zipCode : string = '';
    @Output()isLoadingResults = new EventEmitter<boolean>();
    constructor(private fb : FormBuilder, private router : Router, private service : HttpService) {}

    get rFormControls() {
        return this.rForm.controls;
    }

    ngOnInit() {

        this.rForm = this.fb.group({
            name: [
                '', Validators.required
            ],
            firstName: [
                '', Validators.required
            ],
            lastName: [
                '', Validators.required
            ],
            email: [
                '',
                [
                    Validators.email, Validators.required
                ]
            ],
            phoneNumber: [
                '', Validators.required
            ],
            password: [
                '',
                [
                    Validators.required, Validators.minLength(8)
                ]
            ],
            confirm: [
                '', Validators.required
            ],
            dob: [
                null, Validators.required
            ],
            referralCode: ['']
        }, {
            validator: mustMatch('password', 'confirm')
        });
    }
    // tslint:disable-next-line:use-life-cycle-interface
    ngAfterViewInit() {
        this.isLoadingResults.emit(true);
    }

    // [\+]\d{2}
    // [\+]\d{2}[\(]\d{2}[\)]\d{4}[\-]\d{4}

    // login() {

    // this.isLoadingResults = true;

    // const params = {
    //     companyName: this.rForm.get('companyName'),
    //     email: this.rForm.get('email'),
    //     phoneNumber: this.rForm.get('phoneNumber'),
    //     phoneNumberPrefix: '+92',
    //     password: this.rForm.get('password'),
    // };

    // console.log(params);


    // this.service.putRequest('http://52.42.97.250:3000/api/v1/merchant/login', params)
    //     .pipe(
    //       map(res => res),
    //     )
    //     .subscribe(
    //       res => {
    //         this.isLoadingResults = false;
    //         if (res.success === 1) {
    //           localStorage.setItem('DarkCarz-merchant-data', JSON.stringify(res.data.account));
    //           /*this.show2FA=true;
    //           this.service.showSuccess(res.data.code, 'Code');*/
    //           this.router.navigate(['/merchant/dashboard']);
    //         } else if (res.success === 0) {
    //           this.service.showError(res['message'], 'Sign In');
    //         }
    //       },
    //       err => {
    //         this.isLoadingResults = false;
    //         this.service.showError(err.error.message);
    //       }
    //     );
    // }


    handleAddressChange(address : any) {
        this.userAddress = address.formatted_address

        const addresses = address.address_components;
        addresses.forEach((element, index) => {
            element.types.forEach((type, i) => {
                if (type === 'locality') {
                    this.city = element.long_name;
                } else if (type === 'postal_code') {
                    this.zipCode = element.long_name;
                } else if (type === 'country') {
                    this.country = element.long_name;
                } else if (type === 'administrative_area_level_1') {
                    this.state = element.long_name;
                }
            });
        });
    }

    goToNextStep() { // this.isLoadingResults = true;

        const url = this.globals.urls.signUp.companyRegistration;
        const params = {
            companyName: this.rForm.controls['name'].value,
            firstName: this.rForm.controls['firstName'].value,
            lastName: this.rForm.controls['lastName'].value,
            email: this.rForm.controls['email'].value,
            phoneNumberPrefix: '+1',
            phoneNumber: this.rForm.controls['phoneNumber'].value,
            password: this.rForm.controls['password'].value,
            lineAddress: this.userAddress,
            city: this.city,
            country: this.country,
            postalCode: this.zipCode,
            state: this.state,
            dob: moment(this.rForm.controls['dob'].value).valueOf() / 1000,
            referralCode: this.rForm.controls['referralCode'].value
        };
        console.log(params);
        this.service.postRequest(url, params).subscribe(res => {
            if (res.success === 1) {
                console.log('SUCCESS', res.message);
                this.service.showSuccess(res.message, 'Success');
                // this.isLoadingResults = false;
                this.router.navigate(['registration/verification']);
            } else {
                console.log('ERROR', res.message);
                this.service.showError(res.message, 'ERROR');
                // this.isLoadingResults = false;
            }
        }, err => { // this.isLoadingResults = false;
            console.log('err', err.message);
        });

    }

}
